jQuery(function ($) {
    function loadVimeoPlayer(url, autoplay) {
        var movieID = url;
        movieID = movieID.split('/');
        movieID = movieID[3];
        var movieEmbed = '<iframe src="https://player.vimeo.com/video/' + movieID + '?api=1&autoplay=' + autoplay + '" width="1281" height="720" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>';
        $('#modalPlayer').empty().append(movieEmbed);
    }
 
    function closeVimeoPlayer() {
        $('#modalPlayer').empty();
    }

    $('.vimeo-trigger').on('click', function(e){
        e.preventDefault();
        var vimeoURL = $(this).attr('href');
        loadVimeoPlayer(vimeoURL, 1);
        $('#videoModal').modal('show');
    });

    $('.card--video').on('click', function(){
        var thisLink = $(this).find('.vimeo-trigger'),
            vimeoURL = $(thisLink).attr('href');
        if (vimeoURL) {
            loadVimeoPlayer(vimeoURL, 1);
            $('#videoModal').modal('show');
        }
    });

    $('#videoModal').on('hidden.bs.modal', function (e) {
        closeVimeoPlayer();
    });
});