import ClipboardJS from 'clipboard';

jQuery(function ($) {

    var copyText = 'COPY';
    var copiedText = 'COPIED!';

    var addCopyCodeButtonsEverywhere = function () {
        var elts = $('pre.highlight, .code pre');
        console.log(elts.length);
        elts.wrap(function (i) {
            return $(this).parent('div.codeblock__code').length === 0 ?
                '<div class="codeblock__code"></div>' :
                '';
        });
        elts.wrap(function (i) {
            return '<div id="code-excerpt-' + i + '"></div>';
        });

        elts.parent()
            .parent()
            .prepend(function (i) {

                return '' +
                    '<button title="'+ copyText +'" data-toggle="tooltip" class="codeblock__btn" type="button"' +
                    '    data-clipboard-target="#code-excerpt-' + i + '">' +
                    '  <i class="codeblock__copy"></i>' +
                    '</button>';
            });
        
        // Init Bootstrap Tooltips
        $('.codeblock__btn').tooltip({
            placement: 'bottom',
            boundary: 'viewport'
        });
    };
    
    addCopyCodeButtonsEverywhere(); // Must be before tooltip activation.

    $('.codeblock__btn').tooltip({
        trigger: 'click'
    });

    function setTooltip(btn, message) {
        btn.tooltip('hide')
          .attr('data-original-title', message)
          .tooltip('show');
      }

    function hideTooltip(btn) {
        setTimeout(function() {
            btn.tooltip('hide')
            .attr('data-original-title', copyText);
        }, 500);
    }

    var setupClipboardJS = function() {
        var clipboard = new ClipboardJS('.codeblock__btn');
        clipboard.on('success', function(e){
            var btn = $(e.trigger);
            e.clearSelection();
            setTooltip(btn, copiedText);
            hideTooltip(btn);
        });
    };

    setupClipboardJS();

});

