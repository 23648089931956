const Diff2html = require('diff2html');

const configuration = { inputFormat: 'json', drawFileList: false, matching: 'lines', highlight: true };

function setCodeDiff() {
    const diffBlocks = document.querySelectorAll('.codediff');
    if (diffBlocks) {
    
        diffBlocks.forEach((diffBlock)=> {
            const diffString = diffBlock.querySelector('.codediff__string').textContent;
            const diffJson = Diff2html.parse(diffString);
            const diffTarget = diffBlock.querySelector('.codediff__output');
            const diffHtml = Diff2html.html(diffJson, configuration);
            diffTarget.innerHTML = diffHtml;
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    setCodeDiff();
});

