jQuery(function ($) {

    // Set active class to dropdown items parents
    var activeParent = $('.documentation__sidebar a.active').parents('.dropdown');
    // console.log(activeParent);
    if (activeParent) {
        activeParent.find('> a').attr('aria-expanded', true);
        activeParent.find('> ul').addClass('show');
    }

    // Close Navbar Collapse if click outside in mobile/tablet
    $(document).on('click', function(event) {
        var clickover = $(event.target);
        var $navbar = $('#docSidebar');
        var _opened = $navbar.hasClass('show');
        if (_opened === true && !clickover.hasClass('navbar-collapse') && clickover.parents('#docSidebar').length === 0) {
            $navbar.collapse('hide');
            $('body').removeClass('modal-open');
        }
    });

    // Disable page scroll when sidebar collapse menu is open (mobile)
    $('.documentation__sidebar .navbar-toggle').on('click', function(){
        $('body').addClass('modal-open');
    });
});