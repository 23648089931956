import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { searchBox, index, currentRefinements } from 'instantsearch.js/es/widgets';
import { connectInfiniteHits, connectConfigure, connectSearchBox } from 'instantsearch.js/es/connectors';

// Global Variables
const appID = window.appID;
const apiKey = window.apiKey;
const minCharaters = window.minCharacters;
const docsIndex = window.docsIndex;
const minHits = 3;
const maxHits = 9;
const baseUrl = window.baseUrl;
let curSearchVersion = window.search_version;

// Set Search Client
const searchClient = algoliasearch(appID, apiKey);


// Indice custom filters & counts
const filtersToggle = document.querySelector('.filters__toggle');
const filtersBackdrop = document.querySelector('.filters__backdrop');
const versionRadios = document.querySelectorAll('.filters__facets input[type=radio]');
const currentVersionSpan = document.querySelector('.dropdown-version');
const languageCheckboxes = document.querySelectorAll('.filters__facets input[type=checkbox]');
let checkedLanguages = [];
const refineIndices = document.querySelector('#refineIndices');
const filterAll = document.querySelector('#filterAll');
const countAll = document.querySelector('#countAll');
const filterDocs = document.querySelector('#filterDocs');
const countDocs = document.querySelector('#countDocs');

// Docs
let docsActive = true;
let docsExpanded = false;
const docsHitsContainer = document.querySelector('#hitsDoc');
let docsHasHits = false;
let docsHitsLength = 0;

// Search Query
let searchQuery = '';

// Containers
const searchContainer = document.querySelector('.search');
const container = document.querySelector('.search__results');
const intro = document.querySelector('.search__empty');
const empty = document.querySelector('.search__no-results');
const backToTop = document.querySelector('.search__back');

const search = instantsearch({
    indexName: docsIndex,
    searchClient,
});

// On Hits Render Functions
search.on('render', () => {
    // Check if indices have results
    docsHasHits = docsHitsLength > 0 ? true : false;

    // Show/Hide Indices
    docsHitsContainer.style.display = docsActive && docsHasHits ? 'block' : 'none';

    // Show/Hide Custom Filters
    filterDocs.style.display = docsHasHits ? 'block' : 'none';

    // Update Counts (All)
    const allHitsCount = docsHitsLength;
    const s = allHitsCount > 1 ? 's' : '';
    countAll.textContent = `(${allHitsCount} result${s})`;

    // Containers Display Rules
    searchQuery = document.querySelector('.ais-SearchBox-input').value;
    if(searchQuery.length >= minCharaters) {
        intro.style.display = 'none';
        refineIndices.style.display = 'block';
        if ((docsHasHits)) {
            container.style.display = 'block';
            empty.style.display = 'none';
        } else {
            container.style.display = 'none';
            empty.style.display = 'flex';
        }
    } else {
        intro.style.display = 'flex';
        container.style.display = 'none';
        empty.style.display = 'none';
        refineIndices.style.display = 'none';
    }

    // Show/Hide Back to Top
    const wHeight = window.innerHeight;
    const resultsHeight = document.querySelector('.search__body').offsetHeight;
    backToTop.style.display = wHeight < resultsHeight ? 'block' : 'none';
});

// Indices Template
const infiniteHitsTemplate = (widgetParams, indice, btnText) => {

    // Set Hits Row
    const row = document.createElement('div');
    row.className = 'row results__row';

    // Set Left Column
    const leftCol = document.createElement('div');
    leftCol.className = 'col-12 col-lg-3 results__indice';

    const leftColHeading = document.createElement('h2');
    leftColHeading.className = 'h5';
    leftColHeading.textContent = indice;

    const countHits = document.createElement('span');
    countHits.className = 'results__count';

    // Set Right Column
    const rightCol = document.createElement('div');
    rightCol.className = 'col-12 col-lg-9 results__hits';

    const ul = document.createElement('ul');
    ul.className = 'list-unstyled';

    // More Results Button
    const moreButton = document.createElement('a');
    moreButton.className = 'more-button';
    moreButton.textContent =  btnText;
    moreButton.innerHTML += '<svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12"><path fill="gray" d="M0.570590644,6.89238182 L0.623711653,6.96532482 L4.12371165,10.9653248 C4.15131079,10.9968667 4.18188437,11.0240386 4.21460821,11.0468404 L4.28193271,11.0862424 L4.36572919,11.1178385 L4.43067455,11.1313053 L4.49999315,11.1360725 C4.53717995,11.1360725 4.57341209,11.1320144 4.60827763,11.124317 L4.67880869,11.1031572 L4.71808201,11.0862373 L4.80457234,11.0327807 L4.83722679,11.0051491 L4.87628835,10.9653248 L8.37628835,6.96532482 C8.55812937,6.75750651 8.53707062,6.4416252 8.3292523,6.25978417 C8.14452491,6.09814771 7.87441441,6.09682711 7.68895768,6.24448661 L7.62371165,6.30682022 L4.99996374,9.30303626 L4.99999315,1 C4.99999315,0.723857625 4.77614237,0.5 4.49999315,0.5 C4.25454011,0.5 4.05039163,0.676875161 4.00805567,0.910124368 L3.99999315,1 L3.99996374,9.30603626 L1.37628835,6.30682022 C1.21465188,6.12209282 0.94710704,6.08492854 0.743690698,6.20666316 L0.670747696,6.25978417 C0.509111228,6.40121608 0.460452767,6.62373409 0.532366407,6.81342677 L0.570590644,6.89238182 Z"/></svg>';


    // Next Results Button
    const nextButton = document.createElement('a');
    nextButton.className = 'next-button arrow-link';
    const nextButtonTextSpan = document.createElement('span');
    nextButtonTextSpan.className = 'arrow-link__text';
    nextButtonTextSpan.textContent =  btnText;
    const nextButtonArrowSpan = document.createElement('span');
    nextButtonArrowSpan.className = 'arrow-link__icon';
    nextButtonArrowSpan.innerHTML += '<svg xmlns="http://www.w3.org/2000/svg" width="37" height="9" viewBox="0 0 37 9"><path fill="#DA1B5B" d="M32.2563093,0.570590644 L32.3292523,0.623711653 L36.3433589,4.13653617 C36.3606813,4.1529062 36.376834,4.17050109 36.3916783,4.18918229 L36.3292523,4.12371165 C36.3607942,4.15131079 36.387966,4.18188437 36.4107679,4.21460821 L36.4501699,4.28193271 L36.481766,4.36572919 L36.495265,4.43090702 L36.5,4.49999315 C36.5,4.53717995 36.4959419,4.57341209 36.4882445,4.60827763 L36.4670847,4.67880869 L36.4501648,4.71808201 L36.3967082,4.80457234 L36.3690766,4.83722679 L36.3292523,4.87628835 L32.3292523,8.37628835 C32.121434,8.55812937 31.8055527,8.53707062 31.6237117,8.3292523 C31.4620752,8.14452491 31.4607546,7.87441441 31.6084141,7.68895768 L31.6707477,7.62371165 L34.67,4.999 L1,4.99999315 C0.723857625,4.99999315 0.5,4.77614237 0.5,4.49999315 C0.5,4.25454011 0.676875161,4.05039163 0.910124368,4.00805567 L1,3.99999315 L34.667,3.999 L31.6707477,1.37628835 C31.4860203,1.21465188 31.448856,0.94710704 31.5705906,0.743690698 L31.6237117,0.670747696 C31.7651436,0.509111228 31.9876616,0.460452767 32.1773543,0.532366407 L32.2563093,0.570590644 Z"/></svg>';
    nextButton.appendChild(nextButtonTextSpan);
    nextButton.appendChild(nextButtonArrowSpan);

    // Buttons Container
    const nextButtonContainer = document.createElement('div');
    nextButtonContainer.className = 'results__more';
    nextButtonContainer.appendChild(moreButton);
    nextButtonContainer.appendChild(nextButton);

    // Append Elements
    leftCol.appendChild(leftColHeading);
    leftCol.appendChild(countHits);
    rightCol.appendChild(ul);
    rightCol.appendChild(nextButtonContainer);
    row.appendChild(leftCol);
    row.appendChild(rightCol);
    widgetParams.container.appendChild(row);

};

// Render Docs infinite hits
const renderInfiniteHits = (renderOptions, isFirstRender) => {
    const {
        hits,
        results,
        widgetParams,
        showPrevious,
        isFirstPage,
        showMore,
        isLastPage,
    } = renderOptions;

    let totalHits = 0;

    if(results) {
        totalHits = results.nbHits;
    }
    docsHitsLength = totalHits;
    const s = totalHits > 1 ? 's' : '';

    if (isFirstRender) {
        infiniteHitsTemplate(widgetParams, 'Docs', 'View more results for Docs');
        widgetParams.container.querySelector('.next-button').addEventListener('click', () => {
            showMore();
        });
        return;
    }

    // Update results count
    widgetParams.container.querySelector('.results__count').textContent = `(${totalHits} result${s})`;
    countDocs.textContent = `(${totalHits} result${s})`;

    // Show/Hide More button
    widgetParams.container.querySelector('.more-button').style.display = docsExpanded === true || (docsExpanded === false && isLastPage) ? 'none' : 'block';

    // Show/Hide Next button
    widgetParams.container.querySelector('.next-button').style.display = docsExpanded === false || (docsExpanded === true && isLastPage) ? 'none' : 'block';

    // Append results to list
    widgetParams.container.querySelector('ul').innerHTML = docHitsTemplate(hits);
};

// Docs Hits - Generate breadcrumb from page path
function itemBreadcrumb(url) {
    const here = url.split('/');
    const hereLength = here.length;
    const parts = [];
    for( var i = 1; i < hereLength; i++ ) {
        var part = here[i];
        var text = part.toUpperCase();
        var link = here.slice( 0, i + 1 ).join('/');
        parts.push({ "text": text, "link": link });
    }
    parts.pop();
    return `
        <ul class="result__breadcrumbs list-inline">
            ${parts
                .map(
                    crumb =>
                    `<li class="list-inline-item">
                        <span class="text-muted">${crumb.text.toUpperCase().replace('-', ' ')}</span>
                    </li>`
                )
            .join('>')}
        </ul>`;
};

// Docs hit template
const docHitsTemplate = (hits) => {
    return `
        ${hits
            .map(
            item =>
                `<li class="result">
                    <h3 class="result__title">
                        <a href="${baseUrl}${item.url}">
                            ${instantsearch.highlight({ attribute: 'title', hit: item })}
                        </a>
                    </h3>
                    <p>${item.excerpt_text ? item.excerpt_text.substring(0, 150) + '...' : ''}</p>
                    ${itemBreadcrumb(item.url)}
                </li>`
            )
        .join('')}
    `;
};

// Custom filters toggle active CSS class
const toggleFilters = (activeFilter) => {
    const allFilters = document.querySelectorAll('.filters__indices .nav-link');
    if ( allFilters ) {
        allFilters.forEach((filter)=> {
            filter.classList.remove('active');
        });
    }
    activeFilter.classList.add('active');
}

// Filter All
const toggleAll = (e)=> {
    e.preventDefault();
    if ( !e.target.classList.contains('active') ) {

        const activeFilter = document.querySelector('.filters__indices .nav-link.active');
        if ( activeFilter ) {
            activeFilter.classList.remove('active');
        }
        e.target.classList.add('active');
    }
    docsActive = true;
    docsExpanded = false;
    return false;
}

document.addEventListener('click', (e)=>{
    if (e.target.className == 'ais-SearchBox-reset') {
        const activeFilter = document.querySelector('.filters__indices .nav-link.active');
        if ( activeFilter ) {
            activeFilter.classList.remove('active');
        }
        e.target.classList.add('active');
        filterAll.classList.add('active');
        docsActive = true;
        docsExpanded = false;
        return false;
    }
});

// Set Filters Query
const setFilters = () => {
    let filtersQuery = '';

    curSearchVersion= document.querySelector('input[name="docVersion"]:checked').value;
    console.log(typeof(curSearchVersion));

   /*  let filterVersion = parseFloat(document.querySelector('input[name="docVersion"]:checked').value);

    if ( filterVersion === parseInt(filterVersion)) {
        filterVersion = parseInt(filterVersion);
    } */
    const filterVersion = curSearchVersion;

    const checkedLanguages = document.querySelectorAll('.filters__facets input[type=checkbox]:checked');

    if(checkedLanguages.length > 0) {
        const languagesString = Array.from(checkedLanguages).map(lang => {
            return `tags:${lang.value}`;
        }).join(' OR ');
        filtersQuery = `version:${filterVersion} AND (${languagesString})`;
    } else {
        filtersQuery = `version:${filterVersion}`;
    }

    return filtersQuery;
}

setFilters();

// Render Configure Docs
const renderConfigureDocs = (renderOptions, isFirstRender) => {
    const {
        refine,
        widgetParams
    } = renderOptions;

    if (isFirstRender) {

        refine({
            hitsPerPage: widgetParams.searchParameters.hitsPerPage = minHits,
            filters: setFilters()
        });

        const refineDocs = ()=> {
            refine({
                hitsPerPage: widgetParams.searchParameters.hitsPerPage = maxHits,
                filters: setFilters(),
                page: 0
            });
            // Set Indices visibility
            docsActive = true;
            docsExpanded = true;
        }

        // Select Docs Filter
        filterDocs.addEventListener('click', (e) => {
            e.preventDefault();
            if ( !e.target.classList.contains('active') ) {
                refineDocs();
                toggleFilters(e.target);
            }
            return false;
        });

        // Docs More Button
        docsHitsContainer.addEventListener('click', (e)=> {
            if(e.target.className === 'more-button') {
                refineDocs();
                toggleFilters(filterDocs);
            }
        });

        // Filter All
        filterAll.addEventListener('click', (e)=> {
            refine({
                hitsPerPage: widgetParams.searchParameters.hitsPerPage = minHits,
                filters: setFilters(),
                page: 0
            });
            toggleAll(e);
        });

        // Clear
        document.addEventListener('click', (e)=>{
            if (e.target.className == 'ais-SearchBox-reset') {
                refine({
                    hitsPerPage: widgetParams.searchParameters.hitsPerPage = minHits,
                    filters: setFilters(),
                    page: 0
                });
            }
        });

        // On change Filters
        const onFilterChange = () => {
            const curHitsPerPage = docsExpanded ? maxHits: minHits;
            refine({
                hitsPerPage: widgetParams.searchParameters.hitsPerPage = curHitsPerPage,
                filters: setFilters(),
                page: 0
            });
            currentVersionSpan.textContent = curSearchVersion;
        }

        // Versions radios
        versionRadios.forEach(radio => {
            radio.addEventListener('change', (e) => {
                onFilterChange();
            });
        });

        // Languge Checkboxes
        languageCheckboxes.forEach(check => {
            check.addEventListener('change', (e)=> {
                onFilterChange();
            });
        });
    }
};
const customConfigureDocs = connectConfigure(
    renderConfigureDocs,
    () => {}
);

// Search Box Configurations
const thisSearchBox = searchBox({
    container: '#searchbox',
    placeholder: 'Search for keywords...',
    showSubmit: false,
    showLoadingIndicator: false,
    templates: {
        reset: 'Clear'
    }
});

// Docs Hits Widget
const customHits = connectInfiniteHits(renderInfiniteHits);
const docsHits = index({ indexName: docsIndex }).addWidgets([
    customConfigureDocs({
        container: document.querySelector('#configure'),
        searchParameters: {
            hitsPerPage: minHits

        }
    }),
    customHits({
        container: docsHitsContainer
    })
]);


search.addWidgets([

    // Search Box
    thisSearchBox,

    // Docs Hits
    docsHits,

]);



// Mobile Toggle Filters
function toggleMobileFilters(e){
    e.preventDefault();
    searchContainer.classList.toggle('js-filters');
    e.target.classList.toggle('is-active');
    return false;
}

filtersToggle.addEventListener('click', (e) => {
    toggleMobileFilters(e);
});
filtersBackdrop.addEventListener('click', (e) => {
    toggleMobileFilters(e);
});

// Mobile Sticky Search Input
// Sticky Nav
let prevScrollPos = 0;
let curScrollPos = 0;
const searchDialog = document.querySelector('#searchDialog');
const topBar = document.querySelector('.search__header');
const topBarHeight = topBar.clientHeight;

function stickyNav() {
    curScrollPos = searchDialog.scrollTop;

    if (curScrollPos > prevScrollPos) {
        if (curScrollPos > topBarHeight) {
            topBar.classList.remove('is-sticky');
            topBar.classList.add('is-hidden');
        }
    } else if (curScrollPos < prevScrollPos) {
        if (curScrollPos > topBarHeight) {
            topBar.classList.add('is-sticky');
            topBar.classList.remove('is-hidden');
        } else {
            topBar.classList.remove('is-sticky');
        }
    }
    prevScrollPos = curScrollPos;
}

//searchDialog.onScroll = stickyNav();
searchDialog.addEventListener('scroll', stickyNav);

// Back to Top Link
const backToTopLink = backToTop.querySelector('a');
const searchContainerTop = document.querySelector('#searchTop');
backToTopLink.addEventListener('click', (e)=>{
    e.preventDefault();
    searchContainerTop.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
    });
});

// Bootstrap Modal Focus Search Input
// Needs Ugly jQuery until BS v5...
jQuery(function ($) {
    $('#searchDialog').on('show.bs.modal', function (e) {
        if (!search.started) {
            search.start();
        }
    });

    $('#searchDialog').on('shown.bs.modal', function (e) {
        $('.ais-SearchBox-input').trigger('focus');
    });
});