jQuery(function ($) {
  $('[data-toggle="image-tooltip"]').each(function(){

    var thisImg = $(this).find('img');

    if (thisImg) {
      var imgOriginalWidth = $(thisImg).prop('naturalWidth');
      var imgOriginalHeight = $(thisImg).prop('naturalHeight');
      console.log('width: ' + imgOriginalWidth + ' height:' + imgOriginalHeight);

      var tooltipWidth = $(this).data('width').toString();
      var percentage = 1;

      console.log(tooltipWidth);
      if( tooltipWidth.indexOf('%') > -1 ) {
        var percentage = tooltipWidth.slice(0,-1) / 100
        
      } else {
        var percentage = tooltipWidth / imgOriginalWidth;
      }

      console.log(percentage);

      var imgWidth = imgOriginalWidth * percentage;
      var imgHeight = imgOriginalHeight * percentage;

      $(thisImg).attr({
        width: imgWidth,
        height: imgHeight
      })

      var imgAsText = $(this).find('span').html();

      console.log(imgAsText);

      $(this).attr('title', imgAsText)

    }

    $(this).tooltip({
      animated: 'fade',
      container: 'body',
      customClass: 'image-tooltip',
      // placement: 'bottom',
      html: true
    });
  });

    
});