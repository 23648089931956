jQuery(function ($) {
    $('.clickable').on('click', function(e) {
        e.preventDefault();
        var thisLink = $(this).find('a');
        var thisURL = thisLink.attr('href');
        var thisTarget = thisLink.attr('target');
        if(thisLink.length) {
            if (thisTarget && thisTarget == '_blank') {
                window.open(thisURL);
            } else {
                window.location.href = thisURL;
            }
        }
        return false;
    });
});