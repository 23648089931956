// Lodash
const throttle = require('lodash/throttle');

// Mobile Sticky Nav
let docPrevPos = 0;
let docCurPos = 0;
const siteHeader = document.querySelector('.site-header');

const docTopBar = document.querySelector('.documentation__navigation');
const docSidebar = document.querySelector('.documentation__sidebar');
if( docTopBar && docSidebar) {
    const desktopBarOffset = siteHeader.clientHeight
    const docBarHeight = siteHeader.clientHeight + docTopBar.clientHeight + docSidebar.clientHeight;

    function docStickyNav() {
        docCurPos = window.scrollY;

        if(docCurPos > desktopBarOffset) {
            docTopBar.classList.add('is-sticky-desktop');
        } else {
            docTopBar.classList.remove('is-sticky-desktop');
        }

        if (docCurPos > docPrevPos) {
            if (docCurPos > docBarHeight) {
                docTopBar.classList.remove('is-sticky');
                docTopBar.classList.add('is-hidden');
                docSidebar.classList.remove('is-sticky');
                docSidebar.classList.add('is-hidden');
            }
        } else if (docCurPos < docPrevPos) {
            if (docCurPos > docBarHeight) {
                console.log('is-sticky');
                docTopBar.classList.add('is-sticky');
                docTopBar.classList.remove('is-hidden');
                docSidebar.classList.add('is-sticky');
                docSidebar.classList.remove('is-hidden');
            } else {
                docTopBar.classList.remove('is-sticky');
                docSidebar.classList.remove('is-sticky');
            }
        }
        docPrevPos = docCurPos;
    }
    window.addEventListener('scroll', throttle(docStickyNav, 150));
}

// Heading Anchors
import AnchorJS from 'anchor-js';
const anchors = new AnchorJS();
anchors.options.placement = 'left';
anchors.options.icon = '';
anchors.add('.documentation__body > h2, .documentation__body > h3');

// Content functions
jQuery(function ($) {
    
    // Headings
    $('.documentation__body h2').wrapInner('<span/>');
    $('.documentation__body h3').wrapInner('<span/>');
    $('.documentation__body h4').wrapInner('<span/>');

    // Links with inline code
    $('.documentation__body a').each(function(el){
       if ($(this).children('code').length > 0) {
           $(this).addClass('has-code');
       }
    });

    // Tables
    $('.documentation__body table').not('.rouge-table').not('.d2h-diff-table').addClass('table table-bordered').wrap('<div class="table-responsive" />');

    function replaceText() {
        $('.documentation__body .table td').html(function(i, html){
            var newHTML = html;
            newHTML = newHTML.replace(/\~v/g, '<span class="table-icon table-checkmark" />');
            newHTML = newHTML.replace(/\~x/g, '<span class="table-icon table-cross" />');
            newHTML = newHTML.replace(/\~-/g, '<span class="table-icon table-dash" />');
            return newHTML;
        });
    }

    $(window).on('load', replaceText());

    // Set Documentation Navbar Collapse Text
    var activeSection = $('#docNavbar .nav-link.active');
    if (activeSection.length > 0) {
        var activeText = activeSection.text();
        $('#docActiveSection').text(activeText);
    }

    // Close Documentation Navbar Collapse if click outside in mobile/tablet
    $(document).on('click', function(event) {
        var clickover = $(event.target);
        var $navbar = $('#docNavbar');
        var _opened = $navbar.hasClass('show');
        if (_opened === true && !clickover.hasClass('navbar-collapse') && clickover.parents('#docNavbar').length === 0) {
            $navbar.collapse('hide');
        }
    });

    // Make Media Embeds Responsive
    $('.documentation__body iframe').each(function(){
        $(this).wrap('<div class="responsive-embed"></div>');
        // $('.responsive-embed').unwrap('p');
    });
});
