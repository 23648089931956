jQuery(function ($) {

    // Set active class to dropdown items parents
    var activeParent = $('.site-header__navigation .dropdown-item.active').parents('.dropdown');
    if (activeParent) {
        activeParent.find('> a:first-child').addClass('active');
    }

    // Show/Hide backdrop overlay on mobile
    $('#mainNav').on('show.bs.collapse', function(){
        $('.site-header__backdrop').addClass('is-visible');
    });

    $('#mainNav').on('hidden.bs.collapse', function(){
        $('.site-header__backdrop').removeClass('is-visible');
    });

});